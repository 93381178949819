<template>
    <div class="profit-record">
        <div class="header">
            <p class="can">可提现</p>
            <div class="p-center flex">
                <p class="num">{{ (Number(recordInfo.canbalance || 0) / 100).toFixed(2)}}</p>
                <div class="go-box flex align-center" @click="hrefFun('/balanceCashOut')">
                    <img src="@/assets/img/user/profitRecord/apply.png" alt="">
                    <p>去提现</p>
                </div>
            </div>
        </div>
        <div class="main-box">
            <div class="m-title flex align-center">
                <p class="left">收益记录</p>
                <p class="center">明细</p>
                <img src="@/assets/img/user/profitRecord/enter.png" @click="hrefFun('/cashRecord')">
            </div>
            <div class="m-ul first flex">
                <div class="m-li">
                    <p class="num">¥{{ (Number(recordInfo.totalAmount || 0) / 100).toFixed(2) }}</p>
                    <p class="text">累计到账</p>
                </div>
                <div class="m-li">
                    <p class="num">¥{{ (Number(recordInfo.sumWithdrawAmount || 0) / 100).toFixed(2) }}</p>
                    <p class="text">累计提现</p>
                </div>
                <div class="m-li">
                    <p class="num">¥{{ (Number(recordInfo.canBalanceLockeds || 0) / 100).toFixed(2) }}</p>
                    <p class="text">冻结金额</p>
                </div>
            </div>
            <div class="m-ul second flex">
                <div class="m-li">
                    <p class="num">{{ recordInfo.totalAdjustAmount || 0 }}</p>
                    <p class="text">累计积分</p>
                </div>
                <div class="m-li">
                    <p class="num">{{ recordInfo.usedAdjustAmount || 0 }}</p>
                    <p class="text">已使用积分</p>
                </div>
                <div class="m-li">
                    <p class="num">{{ recordInfo.lockedAdjustAmount || 0 }}</p>
                    <p class="text">未归属积分</p>
                </div>
            </div>
        </div>
        <div class="main-box second-box">
            <div class="m-title flex align-center">
                <p class="left">昨日日报</p>
                <!-- <p class="center" @click="moreFun">查看更多</p>
                <img src="@/assets/img/user/profitRecord/enter.png" @click="moreFun"> -->
            </div>
            <div class="m-ul first flex">
                <div class="m-li">
                    <p class="num">¥{{ (Number(recordInfo.lastDayFansReturn || 0) / 100).toFixed(2) }}</p>
                    <p class="text">直推达人<br/>新增</p>
                </div>
                <div class="m-li">
                    <p class="num">{{ recordInfo.bonusSendDay || 0 }}</p>
                    <p class="text">直推达人<br/>奖励</p>
                </div>
                <div class="m-li">
                    <p class="num">{{ recordInfo.lastDayFans || 0 }}</p>
                    <p class="text">超级达人<br/>转化</p>
                </div>
                <div class="m-li">
                    <p class="num">¥{{ (Number(recordInfo.lastDayFansInvite || 0) / 100).toFixed(2) }}</p>
                    <p class="text">直推达人<br/>引流奖励</p>
                </div>
            </div>
        </div>
        <div class="main-box second-box">
            <div class="m-title flex align-center">
                <p class="left">本月月报</p>
                <!-- <p class="center" @click="moreFun">历史月报</p>
                <img src="@/assets/img/user/profitRecord/enter.png" @click="moreFun"> -->
            </div>
            <div class="m-ul first flex">
                <div class="m-li">
                    <p class="num">¥{{ (Number(recordInfo.nowMonthFansReturn || 0) / 100).toFixed(2) }}</p>
                    <p class="text">直推达人<br/>新增</p>
                </div>
                <div class="m-li">
                    <p class="num">{{ recordInfo.bonusSendMonth || 0 }}</p>
                    <p class="text">直推达人<br/>奖励</p>
                </div>
                <div class="m-li">
                    <p class="num">{{ recordInfo.nowMonthFans || 0 }}</p>
                    <p class="text">超级达人<br/>转化</p>
                </div>
                <div class="m-li">
                    <p class="num">¥{{ (Number(recordInfo.nowMonthFansInvite || 0) / 100).toFixed(2) }}</p>
                    <p class="text">直推达人<br/>引流奖励</p>
                </div>
            </div>
        </div>
    </div>
</template>
<script src="./index.js"></script>
<style lang="scss" scoped>
@import "./index.scss";
</style>