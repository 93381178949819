import { getRevenueRecord } from "@/utils/user.js"
export default {
    name: "ProfitRecord",
    data() {
        return {
            recordInfo: {}
        }
    },
    created() {
        this.getRecord()
    },
    methods: {
        moreFun() {
        //   this.$router.push("/cashRecord")
        },
        getRecord() {
            let data = {
                memberId: this.$cookies.get('memberId')
              };
            getRevenueRecord(data).then(res => {
                if (res && res.code == "0") {
                  this.recordInfo = res.data || {}
                }
              })
        },
        hrefFun(val) {
          this.$router.push(val)
        },
        showFun() {
            this.isShow = true
        }
    }
}